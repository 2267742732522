import React from 'react';
import MathDinosaurGame from './MathDinosaurGame';

function App() {
  return (
    <div className="App">
      <MathDinosaurGame />
    </div>
  );
}

export default App;